import React from 'react';
import { BarChart, Bar, XAxis, YAxis, LabelList, Cell } from 'recharts';

export class PriceCalculator extends React.Component {
  constructor() {
    super();
    this.state = {
      calls: 10000
    }
  }

  render() {
    const outboundMinutes = this.state.calls * 2;
    const inboundMinutes = Math.ceil(this.state.calls * 0.04);
    return (
      <div css={{ textAlign: 'center' }}>
        <p css={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '30px' }}>If you plan to call <NumberInput value={this.state.calls} onChange={(value) => this.setState({ calls: Math.min(value, 72000) })} /> voters...</p>
        <small>We expect that this will use roughly {outboundMinutes} outbound minutes and {inboundMinutes} inbound minutes.</small>
        <br />
        <small><i><b>Let's see how much you can save.</b></i></small>
        <br /><br />
        <PriceGraph outbound={outboundMinutes} inbound={inboundMinutes} />
      </div>
    );
  }
}

const PriceGraph = (props) => {
  const { outbound, inbound } = props;

  const getCallFirePrice = (outbound, inbound) => {
    const minutes = outbound + inbound;
    if (minutes >= 1000000) {
      return 20000;
    } else if (minutes >= 100000) {
      return 2500;
    } else if (minutes >= 55000) {
      return 1500;
    } else if (minutes >= 30000) {
      return 850;
    } else if (minutes >= 20000) {
      return 599;
    } else if (minutes >= 10000) {
      return 299;
    } else if (minutes >= 5500) {
      return 199;
    } else if (minutes >= 2500) {
      return 99;
    } else {
      return minutes * 0.05;
    }
  }

  const data = [
    { name: 'CrowdCall', price: Math.ceil(outbound * 0.009 + inbound * 0.015) },
    { name: 'CallHub', price: Math.ceil((outbound + inbound) * 0.016) },
    { name: 'GetThru', price: Math.ceil((outbound + inbound) * 0.0275) },
    { name: 'CallFire', price: Math.ceil(getCallFirePrice(outbound, inbound)) },
    //{ name: 'NGPVan', price: Math.ceil(minutes * 0.14) },
  ];

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height } = props;
    let price = data[props.index].price;
    const isCrowdcall = props.index === 0;

    return (
      <g>
        <style>
          .small {'{ font: italic 13px sans-serif; }'}
        </style>
        <text x={x + width + 10} y={y + (height * 0.65)} fill={isCrowdcall ? "green" : "grey"}>
          ${price}
          <tspan class="small">{!isCrowdcall ? ` ($${price - data[0].price} extra)` : ' (Lowest Price!)'}</tspan>
        </text>
      </g>
    );
  };

  return (<BarChart layout="vertical" margin={{ top: 0, right: 120, left: 60, bottom: 0 }} css={{ margin: "0 auto" }} width={600} height={200} data={data}>
    <XAxis hide type="number" />
    <YAxis dataKey="name" type="category" />
    <Bar dataKey="price" fill="#8884d8">
      {
        data.map((entry, index) => (
          <Cell fill={index === 0 ? 'green' : 'grey'} />
        ))
      }
      <LabelList dataKey="price" content={renderCustomizedLabel} />
    </Bar>
  </BarChart>);
}


const NumberInput = (props) => <input type="number" css={
  { display: 'inline', height: 'auto', border: 0, margin: 0, outline: 0, background: 'transparent', fontSize: '30px', width: '130px', textAlign: 'center', borderBottom: '3px solid #2962FF' }
} value={props.value} onInput={(e) => props.onChange(Math.abs(e.target.value))} />;