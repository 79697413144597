import React from 'react';
import styled from '@emotion/styled';

const PriceDescriptionDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceDescription = (props) => <div css={{ margin: "50px 0"}}>
  <PriceDescriptionDiv>
    <div css={{ borderRight: "#E5E5E5 5px solid", maxWidth: "700px", paddingRight: "15px", height: "150px" }}>
      <h3 css={{ fontSize: "22px" }}>{props.title}</h3>
      <p>{props.description}</p>
    </div>
    <div css={{padding: "15px", width: "200px"}}>
      <span css={{ fontSize: "18px", color: '#41AB00' }}>{props.price}</span>
      <p css={{ color: "#6F6F6F" }}>{props.priceUnit}</p>
    </div>
  </PriceDescriptionDiv>
</div>